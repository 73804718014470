import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './rootEpic';
import { createStore, applyMiddleware } from 'redux';

import reducer from './rootReducer';

const epicMiddleware = createEpicMiddleware();

const store = createStore(reducer, composeWithDevTools(applyMiddleware(epicMiddleware)));
epicMiddleware.run(rootEpic);

export default store;

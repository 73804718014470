import { combineEpics } from 'redux-observable';

import * as  AuthEpic from './reducers/auth/epic';
import * as  rolesEpic from './reducers/roles/epic';
import * as  permissionsEpic from './reducers/permissions/epic';
import * as  userOperationTypesEpic from './reducers/userOperationTypes/epic';
import * as  adminOperationTypesEpic from './reducers/adminOperationTypes/epic';
import * as  ossTokenEpic from './reducers/ossToken/epic';

import * as  UserEpic from '../pages/UserList/reducer/epic';
import * as  AdminEpic from '../pages/AdminList/reducer/epic';
import * as  RoleListEpic from '../pages/RoleList/reducer/epic';
import * as  UserOptListEpic from '../pages/UserOptList/reducer/epic';
import * as  AdminOptListEpic from '../pages/AdminOptList/reducer/epic';
import * as  BrandListEpic from '../pages/BrandList/reducer/epic';
import * as  CheckListEpic from '../pages/AntiFakeQueryList/reducer/epic';
import * as  ProfileEpic from '../pages/Profile/reducer/epic';
import * as  ProductCheckEpic from '../pages/ProductCheck/reducer/epic';


export const rootEpic = combineEpics(
  //
  ...Object.values(ossTokenEpic),
  ...Object.values(rolesEpic),
  ...Object.values(permissionsEpic),
  ...Object.values(AuthEpic),
  ...Object.values(userOperationTypesEpic),
  ...Object.values(adminOperationTypesEpic),
  //
  ...Object.values(UserEpic),
  ...Object.values(AdminEpic),
  ...Object.values(UserOptListEpic),
  ...Object.values(AdminOptListEpic),
  ...Object.values(RoleListEpic),
  ...Object.values(BrandListEpic),
  ...Object.values(CheckListEpic),
  ...Object.values(ProfileEpic),
  ...Object.values(ProductCheckEpic),
);

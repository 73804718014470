import * as types from './actionTypes';
import { fromJS } from 'immutable';

export const tokenRequest = (data = {}, onSuccess = null) => ({
  type: types.TOKEN_REQUEST,
  payload: fromJS({data, onSuccess}),
});

export const tokenSuccess = (data) => ({
  type: types.TOKEN_SUCCESS,
  payload: fromJS(data),
});

export const tokenError = () => ({
  type: types.TOKEN_ERROR,
});

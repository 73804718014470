import React from 'react';
import { Menu, Layout } from "antd";
import { Logo, Flex } from '../../components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleSiderCollapsed } from '../../store/reducers/app/actions';
import { menus } from './menus';
// import { UserOutlined } from '@ant-design/icons';

const {Sider} = Layout;
const SubMenu = Menu.SubMenu;
const mapStateToProps = (state) => ({
  auth: state.get('auth'),
});

class SideMenu extends React.PureComponent {

  handleClick = ({key}) => {
    this.props.history.push(`/${key}`);
  };

  render() {
    const {location, toggleSiderCollapsed, auth} = this.props;
    const path = location.pathname.substr(1).split('/');
    const selected = path.length === 1 ? path[0] : path[1];
    if (!auth.getIn(['user', 'permissions'])) {
      return null
    }
    const permissions = auth.getIn(['user', 'permissions']);
    return (
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
        }}
        onCollapse={(v) => {
          toggleSiderCollapsed(v)
        }}>
        <Flex style={{height: "64px"}} align="center" justify="center">
          <Logo type="white" />
        </Flex>
        <Menu theme="dark" mode="inline"
              selectedKeys={[selected]}
              onClick={this.handleClick}>
          {
            menus.map(menu => {
              const p = menu.children.map(v => v.permission);
              if (p.some(v => permissions.includes(v))) {
                return (
                  <SubMenu key={menu.key} title={<span><span>{menu.title}</span></span>}>
                    {
                      menu.children.map(child => {
                        if (permissions.includes(child.permission)) {
                          return (
                            <Menu.Item key={child.key}>{child.title}</Menu.Item>
                          )
                        } else {
                          return null
                        }
                      })
                    }
                  </SubMenu>
                )
              } else {
                return null;
              }
            })
          }
        </Menu>
      </Sider>
    );
  }
}

export default withRouter(connect(mapStateToProps, {toggleSiderCollapsed})(SideMenu));

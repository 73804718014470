const menus = [
  {
    key: "dashboard",
    icon: "dashboard",
    title: "Dashboard",
    children: [
      {
        key: "platformDashboard",
        title: "Dashborad",
        permission: "品牌列表"
      },
      {
        key: "productCheck",
        title: "产品防伪查询",
        permission: "产品防伪查询"
      },
      {key: "AntiFakeQueryList", title: "防伪查询记录", permission: "防伪查询记录"},
    ]
  },
  {
    key: "userManagement",
    icon: "user",
    title: "用户管理",
    children: [
      {key: "userList", title: "用户列表", permission: "用户列表"},
      {key: "userOptList", title: "用户操作记录", permission: "用户操作记录"},
    ]
  },
  {
    key: "brandOperation",
    icon: "user",
    title: "品牌管理",
    children: [
      {key: "brandList", title: "品牌列表", permission: "品牌列表"},
    ]
  },
  {
    key: "system",
    icon: "user",
    title: "系统管理",
    children: [
      {key: "adminList", title: "管理员列表", permission: "管理员列表"},
      {key: "roleList", title: "角色列表", permission: "角色列表"},
      {key: "adminOptList", title: "管理员操作记录", permission: "管理员操作记录"},
    ]
  },
];

export {
  menus,
}

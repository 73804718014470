import { combineReducers } from 'redux-immutable';
import { Map } from 'immutable';
import * as types from './actionTypes';

export const REDUX_KEY = "product";

const INIT_CHECK = Map({
  loading: false,
  item: Map(),
});

const check = (state = INIT_CHECK, action) => {
  switch (action.type) {
    case types.CHECK_REQUEST:
      return state.set('loading', true);
    case types.CHECK_ERROR:
      return state.set('loading', false);
    case types.CHECK_SUCCESS:
      return state.set('loading', false)
        .set('item', action.payload);
    default:
      return state;
  }
};

const reducer = combineReducers({
  check,
});

export default reducer;

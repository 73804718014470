import { Map } from 'immutable';
import * as types from './actionTypes';

export const REDUX_KEY = "oss_token";

// TOKEN
const INIT_TOKEN = Map({
  loading: false,
  token: Map(),
});

const detail = (state = INIT_TOKEN, action) => {
  switch (action.type) {
    case types.TOKEN_REQUEST:
      return state.set('loading', true);
    case types.TOKEN_ERROR:
      return state.set('loading', false);
    case types.TOKEN_SUCCESS:
      return state.set('loading', false)
        .set('token', action.payload)
    default:
      return state;
  }
};

const reducer = detail;

export default reducer;

import * as types from './actionTypes';
import {
  loginSuccess, loginError,
  fetchMeSuccess, fetchMeError,
  //
  logoutError, logoutSuccess,
} from './actions';
import { ofType } from 'redux-observable';
import { switchMap, map, debounceTime, catchError } from 'rxjs/operators'
import http from '../../../utils/http';
import { fromPromise } from "rxjs/internal-compatibility";
import { of } from 'rxjs'
import { message } from "antd";
import { TOKEN_NAME } from '../../../constants/app'

export const loginEpic = (actions$, state$) => {
  return actions$.pipe(
    ofType(types.LOGIN_REQUEST),
    debounceTime(300),
    switchMap(action => {
      const {data, onSuccess} = action.payload.toJS();
      return fromPromise(http.post('/login', data)).pipe(
        map(response => {
          const {token} = response.data.data;
          localStorage.setItem(TOKEN_NAME, token);
          onSuccess && onSuccess();
          return loginSuccess(response.data.data)
        }),
        catchError(err => {
          message.error(err.response.data.message);
          return of(loginError())
        }))
    }),
  )
};

export const logoutEpic = (actions$, state$) => {
  return actions$.pipe(
    ofType(types.LOGOUT_REQUEST),
    debounceTime(300),
    switchMap(action => {
      const {onSuccess} = action.payload.toJS();
      return fromPromise(http.post('/logout')).pipe(
        map(response => {
          message.success(response.data.message);
          onSuccess && onSuccess();
          return logoutSuccess()
        }),
        catchError(err => {
          message.error(err.response.data.message);
          return of(logoutError())
        }))
    }),
  )
};

export const meEpic = (actions$, state$) => {
  return actions$.pipe(
    ofType(types.FETCH_ME_REQUEST),
    debounceTime(300),
    switchMap(action => {
      return fromPromise(http.post('/me')).pipe(
        map(response => {
          return fetchMeSuccess(response.data.data)
        }),
        catchError(err => {
          return of(fetchMeError())
        }))
    }),
  )
};

import React from 'react';
import {  Upload, message } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { tokenRequest } from '../../store/reducers/ossToken/actions'

const mapStateToProps = (state) => ({
  token: state.getIn(['oss_token', 'token']),
});


class AliyunOSSUpload extends React.Component {

  state = {
    loading: false,
  };

  transformFile = file => {
    const {token} = this.props;
    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    const filename = Date.now() + suffix;
    file.url = token.get('dir') + filename;

    return file;
  };

  getExtraData = file => {
    const {token} = this.props;
    return {
      key: file.url,
      OSSAccessKeyId: token.get('accessid'),
      policy: token.get('policy'),
      Signature: token.get('signature'),
    };
  };

  beforeUpload = () => {
    const {token, tokenRequest} = this.props;
    const expire = token.get('expire') * 1000;

    if (expire < Date.now()) {
      tokenRequest()
    }
    return true;
  };

  render() {
    const {value, token, onChange} = this.props;
    const {loading} = this.state;
    const props = {
      name: 'file',
      action: token.get('host'),
      onChange: (info) => {
        if (info.file.status === 'uploading') {
          this.setState({loading: true});
        }
        if (info.file.status === 'done') {
          const url = token.get('host') + '/' + info.file.originFileObj.url;
          onChange(url);
          this.setState({loading: false})
          message.success(`上传成功`);
        } else if (info.file.status === 'error') {
          message.error(`上传失败.`);
        }
      },
      showUploadList: false,
      listType: 'picture-card',

      transformFile: this.transformFile,
      data: this.getExtraData,
      beforeUpload: this.beforeUpload,
    };
    return (
      <Upload {...props}>
        {loading ? <LoadingOutlined /> : (
          value ? <img width="100" src={value} alt="" /> : <UploadOutlined />
        )}
      </Upload>
    );
  }
}

export default connect(mapStateToProps, {tokenRequest})(AliyunOSSUpload);

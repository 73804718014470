import * as types from './actionTypes';
import {
  tokenSuccess, tokenError
} from './actions';
import { ofType } from 'redux-observable';
import { switchMap, map, debounceTime, catchError } from 'rxjs/operators'
import http from '../../../utils/http';
import { fromPromise } from "rxjs/internal-compatibility";
import { of } from 'rxjs'

export const listEpic = (actions$, state$) => {
  return actions$.pipe(
    ofType(types.TOKEN_REQUEST),
    debounceTime(300),
    switchMap(action => {
      const {onSuccess} = action.payload.toJS();
      return fromPromise(http.post('/oss/token')).pipe(
        map(response => {
          onSuccess && onSuccess();
          return tokenSuccess(response.data.data)
        }),
        catchError(err => {
          return of(tokenError())
        }))
    }),
  )
};

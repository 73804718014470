import React from 'react';
import { Menu } from 'antd';
import { Flex } from '../../components';
import styles from './index.module.scss';
import { withRouter } from 'react-router-dom';
import { logoutRequest, fetchMeRequest } from '../../store/reducers/auth/actions';
import { connect } from 'react-redux';

const SubMenu = Menu.SubMenu;
const mapStateToProps = state => ({
  auth: state.get('auth'),
});

class PageHeader extends React.PureComponent {

  componentDidMount() {
    this.props.fetchMeRequest();
  }

  render() {
    const {history, logoutRequest, auth} = this.props;
    return (
      <Flex justify="flex-end">
        <Menu theme="dark"
              mode="horizontal"
              className={styles.pageHeader}
              onClick={({key}) => {
                if (key === 'logout') {
                  logoutRequest({onSuccess: () => history.push('/login')});
                } else {
                  history.push(`/${key}`)
                }
              }}>
          <SubMenu key="sub1" title={<span><span>你好，{auth.getIn(['user', 'name'])}</span></span>}>
            <Menu.Item key="profile">账户信息</Menu.Item>
            <Menu.Item key="logout">退出</Menu.Item>
          </SubMenu>
        </Menu>
      </Flex>
    );
  }
}

export default withRouter(connect(mapStateToProps, {logoutRequest, fetchMeRequest})(PageHeader));

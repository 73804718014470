import React from 'react';
import { Button } from "antd";

const DrawerFooter = ({detail, list, detailRequest, listRequest}) => {

  const handleNext = () => {
    const filters = list.get('filters');
    const currentId = detail.get('id');
    const index = list.get('items').findIndex((v) => v.get('id') === currentId);
    // 到达当页最后一条数据
    if (index === list.get('items').size - 1) {
      const totalPage = Math.ceil(list.get('total') / filters.get('pageSize'));
      // 可以发起下一项请求
      if (filters.get('current') < totalPage) {
        const data = {current: filters.get('current') + 1};
        const onSuccess = (data) => {
          detailRequest({id: data.items[0]['id']});
        };
        listRequest(data, onSuccess);
      }
    } else {
      // 直接读取下一项数据
      detailRequest({id: list.getIn(['items', index + 1, 'id'])});
    }
  };

  const handlePrev = () => {
    const filters = list.get('filters');
    const currentId = detail.get('id');
    const index = list.get('items').findIndex((v) => v.get('id') === currentId);
    // 到达当页第一条数据
    if (index === 0) {
      if (filters.get('current') !== 1) {
        const data = {current: filters.get('current') - 1};
        const onSuccess = (data) => {
          detailRequest({id: data.items[data.items.length - 1]['id']});
        };
        listRequest(data, onSuccess);
      }
    } else {
      // 直接读取上一项数据
      detailRequest({id: list.getIn(['items', index - 1, 'id'])});
    }
  };

  const hasNext = () => {
    const filters = list.get('filters');
    const currentId = detail.get('id');
    const index = list.get('items').findIndex((v) => v.get('id') === currentId);
    // 到达当页最后一条数据
    if (index === list.get('items').size - 1) {
      const totalPage = Math.ceil(list.get('total') / filters.get('pageSize'));
      // 可以发起下一项请求
      return filters.get('current') < totalPage;
    } else {
      return true;
    }
  };

  const hasPrev = () => {
    const filters = list.get('filters');
    const currentId = detail.get('id');
    const index = list.get('items').findIndex((v) => v.get('id') === currentId);
    // 到达当页第一条数据
    if (index === 0) {
      return filters.get('current') !== 1;
    } else {
      // 直接读取上一项数据
      return true;
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        display: "none",
        // display: "flex",
        justifyContent: "space-between",
        borderTop: '1px solid #e8e8e8',
        padding: '20px 16px',
        textAlign: 'right',
        left: 0,
        zIndex: 999,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
      }}
    >
      <Button style={{marginRight: 8}} onClick={handlePrev} disabled={!hasPrev()}>上一项</Button>
      <Button type="primary" onClick={handleNext} disabled={!hasNext()}>下一项</Button>
    </div>
  );
}

export default DrawerFooter;

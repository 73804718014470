import * as types from './actionTypes';
import { fromJS } from 'immutable';

// LIST
export const checkRequest = (data = {}, onSuccess = null) => ({
  type: types.CHECK_REQUEST,
  payload: fromJS({data, onSuccess}),
});

export const checkSuccess = (data) => ({
  type: types.CHECK_SUCCESS,
  payload: fromJS(data),
});

export const checkError = () => ({
  type: types.CHECK_ERROR,
});

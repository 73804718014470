import React from 'react';
import styles from './index.module.scss';
import logo from './logo.png';
import logoWhite from './logo-white.png';

const Logo = ({marginRightGutter = false, style, type = "default"}) => (
  <img src={type === "default" ? logo : logoWhite} alt="logo" className={styles.wrap} style={Object.assign({
    marginRight: marginRightGutter ? "10px" : "0",
  }, style)} />
);

export default Logo;

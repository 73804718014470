import * as types from './actionTypes';
import {
  listSuccess, listError, detailSuccess, detailError, updateSuccess, updateError, createSuccess, createError,
} from './actions';
import { ofType } from 'redux-observable';
import { switchMap, map, debounceTime, catchError } from 'rxjs/operators'
import { REDUX_KEY } from './index';
import http from '../../../utils/http';
import { fromPromise } from "rxjs/internal-compatibility";
import { of } from 'rxjs'
import { message } from 'antd';

export const listEpic = (actions$, state$) => {
  return actions$.pipe(
    ofType(types.LIST_REQUEST),
    debounceTime(300),
    switchMap(action => {
      const filters = state$.value.getIn([REDUX_KEY, 'list', 'filters']).toJS();
      const {onSuccess} = action.payload.toJS();
      return fromPromise(http.post('/role/list', filters)).pipe(
        map(response => {
          onSuccess && onSuccess();
          return listSuccess(response.data.data)
        }),
        catchError(err => {
          return of(listError())
        }))
    }),
  )
};

export const detailEpic = (actions$, state$) => {
  return actions$.pipe(
    ofType(types.DETAIL_REQUEST),
    debounceTime(300),
    switchMap(action => {
      const {data, onSuccess} = action.payload.toJS();
      return fromPromise(http.post('/role/detail', data)).pipe(
        map(response => {
          onSuccess && onSuccess();
          return detailSuccess(response.data.data)
        }),
        catchError(err => {
          return of(detailError())
        }))
    }),
  )
};

export const updateEpic = (actions$, state$) => {
  return actions$.pipe(
    ofType(types.UPDATE_REQUEST),
    debounceTime(300),
    switchMap(action => {
      const {data, onSuccess} = action.payload.toJS();
      return fromPromise(http.post('/role/update', data)).pipe(
        map(response => {
          message.success("更新成功!");
          onSuccess && onSuccess();
          return updateSuccess()
        }),
        catchError(err => {
          return of(updateError())
        }))
    }),
  )
};

export const createEpic = (actions$, state$) => {
  return actions$.pipe(
    ofType(types.CREATE_REQUEST),
    debounceTime(300),
    switchMap(action => {
      const {data, onSuccess} = action.payload.toJS();
      return fromPromise(http.post('/role/create', data)).pipe(
        map(response => {
          onSuccess && onSuccess();
          return createSuccess()
        }),
        catchError(err => {
          return of(createError())
        }))
    }),
  )
};

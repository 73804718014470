import { combineReducers } from 'redux-immutable';

import app from './reducers/app';
import auth from './reducers/auth';
import roles from './reducers/roles';
import permissions from './reducers/permissions';
import userOperationTypes, { REDUX_KEY as UserOperationTypesKey } from './reducers/userOperationTypes';
import adminOperationTypes, { REDUX_KEY as AdminOperationTypesKey } from './reducers/adminOperationTypes';
import ossToken, { REDUX_KEY as ossTokenKey } from './reducers/ossToken';

import UserReducer, { REDUX_KEY as UserReducerKey } from '../pages/UserList/reducer';
import AdminList, { REDUX_KEY as AdminListReducerKey } from '../pages/AdminList/reducer';
import UserOptList, { REDUX_KEY as UserOptListReducerKey } from '../pages/UserOptList/reducer';
import AdminOptList, { REDUX_KEY as AdminOptListReducerKey } from '../pages/AdminOptList/reducer';
import RoleList, { REDUX_KEY as RoleListReducerKey } from '../pages/RoleList/reducer';
import BrandList, { REDUX_KEY as BrandListReducerKey } from '../pages/BrandList/reducer';
import CheckList, { REDUX_KEY as CheckListReducerKey } from '../pages/AntiFakeQueryList/reducer';
import Profile, { REDUX_KEY as ProfileReducerKey } from '../pages/Profile/reducer';
import ProductCheck, { REDUX_KEY as ProductCheckReducerKey } from '../pages/ProductCheck/reducer';

const rootReducer = combineReducers({
  app,
  auth,
  roles,
  permissions,
  [UserOperationTypesKey]: userOperationTypes,
  [ossTokenKey]: ossToken,
  [AdminOperationTypesKey]: adminOperationTypes,
  //
  [UserReducerKey]: UserReducer,
  [AdminListReducerKey]: AdminList,
  [UserOptListReducerKey]: UserOptList,
  [AdminOptListReducerKey]: AdminOptList,
  [RoleListReducerKey]: RoleList,
  [BrandListReducerKey]: BrandList,
  [CheckListReducerKey]: CheckList,
  [ProfileReducerKey]: Profile,
  [ProductCheckReducerKey]: ProductCheck,
});

export default rootReducer;

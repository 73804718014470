import {combineReducers} from 'redux-immutable';
import {Map} from 'immutable';
import * as types from './actionTypes';

export const REDUX_KEY = "profile";

// UPDATE
const INIT_UPDATE = Map({
  loading: false,
});

const update = (state = INIT_UPDATE, action) => {
  switch (action.type) {
    case types.UPDATE_REQUEST:
      return state.set('loading', true);
    case types.UPDATE_SUCCESS:
    case types.UPDATE_ERROR:
      return state.set('loading', false);
    default:
      return state;
  }
};

const reducer = combineReducers({
  update,
});

export default reducer;

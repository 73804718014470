import { Map } from 'immutable';
import * as types from './actionTypes';

export const REDUX_KEY = "admin_operation_types";

// SEARCH
const INIT_SEARCH = Map({
  loading: false,
  items: Map(),
});

const reducer = (state = INIT_SEARCH, action) => {
  switch (action.type) {
    case types.DATA_REQUEST:
      return state.set('loading', true);
    case types.DATA_ERROR:
      return state.set('loading', false);
    case types.DATA_SUCCESS:
      return state
        .set('loading', false)
        .set('items', action.payload);
    default:
      return state;
  }
};

export default reducer;

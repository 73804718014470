import React from 'react';
import { message, Upload } from 'antd';
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";

class UploadFile extends React.PureComponent {

  state = {
    loading: false,
  };

  render() {
    const {onChange, value} = this.props;
    const uploadUrl = process.env.REACT_APP_BASE_URL + '/upload';
    const {loading} = this.state;
    const props = {
      name: 'file',
      action: uploadUrl,
      listType: 'picture-card',
      showUploadList: false,
      onChange: (info) => {
        if (info.file.status === 'uploading') {
          this.setState({loading: true});
        }
        if (info.file.status === 'done') {
          onChange(info.file.response.data.url);
          this.setState({loading: false})
          message.success(`上传成功`);
        } else if (info.file.status === 'error') {
          message.error(`上传失败.`);
        }
      },
    };
    return (
      <>
        <Upload {...props}>
          {loading ? <LoadingOutlined /> : (
            value ? <img width="100" src={value} alt="" /> : <UploadOutlined />
          )}
        </Upload>
      </>
    )
  }
}

export default UploadFile;

import React from 'react';
import { Tag } from 'antd';

const Display = ({value}) => {
  switch (value) {
    case 0:
      return <Tag>未知</Tag>;
    case 1:
      return <Tag>男</Tag>;
    case 2:
      return <Tag>女</Tag>;
    default:
      return null;
  }
};

export default Display;

import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import BasicPageLayout from './layouts/BasicPageLayout';
import { LOGIN_PATH } from './constants/app';
import Login from './pages/Login';
import { GuestRoute } from './components'
import './app.scss';

const App = () => (
  <BrowserRouter>
    <Switch>
      <GuestRoute path={`/${LOGIN_PATH}`} component={Login} />
      <Route path="/" component={BasicPageLayout} />
    </Switch>
  </BrowserRouter>
);

export default App;

import * as types from './actionTypes';
import {
  listSuccess, listError
} from './actions';
import { ofType } from 'redux-observable';
import { switchMap, map, debounceTime, catchError } from 'rxjs/operators'
import { REDUX_KEY } from './index';
import http from '../../../utils/http';
import { fromPromise } from "rxjs/internal-compatibility";
import { of } from 'rxjs'

export const listEpic = (actions$, state$) => {
  return actions$.pipe(
    ofType(types.LIST_REQUEST),
    debounceTime(300),
    switchMap(action => {
      const filters = state$.value.getIn([REDUX_KEY, 'list', 'filters']).toJS();
      const {onSuccess} = action.payload.toJS();
      return fromPromise(http.post('/admin/operation/list', filters)).pipe(
        map(response => {
          onSuccess && onSuccess();
          return listSuccess(response.data.data)
        }),
        catchError(err => {
          return of(listError())
        }))
    }),
  )
};

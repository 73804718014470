import * as types from './actionTypes';
import { fromJS } from 'immutable';

// SEARCH
export const dataRequest = (data = {}, onSuccess = null) => ({
  type: types.DATA_REQUEST,
  payload: fromJS({data, onSuccess}),
});

export const dataSuccess = (data) => ({
  type: types.DATA_SUCCESS,
  payload: fromJS(data),
});

export const dataError = () => ({
  type: types.DATA_ERROR,
});

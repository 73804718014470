// FETCH ME
export const FETCH_ME_REQUEST = "FETCH_ME_REQUEST";
export const FETCH_ME_SUCCESS = "FETCH_ME_SUCCESS";
export const FETCH_ME_ERROR = "FETCH_ME_ERROR";

// LOGOUT
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

// LOGIN
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const ACCOUNT_CHECK_REQUEST = "ACCOUNT_CHECK_REQUEST";
export const ACCOUNT_CHECK_SUCCESS = "ACCOUNT_CHECK_SUCCESS";
export const ACCOUNT_CHECK_ERROR = "ACCOUNT_CHECK_ERROR";

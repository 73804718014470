import * as types from './actionTypes';
import { fromJS } from 'immutable';

// FILTERS
export const resetFilters = () => ({
  type: types.RESET_FILTERS,
});

// LIST
export const listRequest = (data = {}, onSuccess = null) => ({
  type: types.LIST_REQUEST,
  payload: fromJS({data, onSuccess}),
});

export const listSuccess = (data) => ({
  type: types.LIST_SUCCESS,
  payload: fromJS(data),
});

export const listError = () => ({
  type: types.LIST_ERROR,
});

// CREATE
export const createRequest = (data = {}, onSuccess = null) => ({
  type: types.CREATE_REQUEST,
  payload: fromJS({data, onSuccess}),
});

export const createSuccess = () => ({
  type: types.CREATE_SUCCESS,
});

export const createError = () => ({
  type: types.CREATE_ERROR,
});

// DELETE
export const deleteRequest = (data = [], onSuccess = null) => ({
  type: types.DELETE_REQUEST,
  payload: fromJS({data, onSuccess}),
});

export const deleteSuccess = () => ({
  type: types.DELETE_SUCCESS,
});

export const deleteError = () => ({
  type: types.DELETE_ERROR,
});

// DETAIL
export const detailRequest = (data = {}, onSuccess = null) => ({
  type: types.DETAIL_REQUEST,
  payload: fromJS({data, onSuccess}),
});

export const detailSuccess = (data) => ({
  type: types.DETAIL_SUCCESS,
  payload: fromJS(data),
});

export const detailError = () => ({
  type: types.DETAIL_ERROR,
});

// UPDATE
export const updateRequest = (data = {}, onSuccess = null) => ({
  type: types.UPDATE_REQUEST,
  payload: fromJS({data, onSuccess}),
});

export const updateSuccess = () => ({
  type: types.UPDATE_SUCCESS,
});

export const updateError = () => ({
  type: types.UPDATE_ERROR,
});


// OTHERE
export const toggleDetailVisible = (data) => ({
  type: types.TOGGLE_DETAIL_VISIBLE,
  payload: data,
});

export const toggleUpdateVisible = (data) => ({
  type: types.TOGGLE_UPDATE_VISIBLE,
  payload: data,
});

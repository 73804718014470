import React from 'react';
import {Flex, Logo} from "../index";
import {Divider} from "antd";

const PassportFormTitle = ({title}) => (
  <Flex align="center" justify="center">
    <Logo/>
    <Divider type="vertical" style={{height: "20px", margin: "0 20px"}}/>
    <span style={{fontSize: "20px", fontWeight: "300"}}>{title}</span>
  </Flex>
);

export default PassportFormTitle;

import * as types from './actionTypes';
import { fromJS } from 'immutable';

// UPDATE
export const updateRequest = (data = {}, onSuccess = null) => ({
  type: types.UPDATE_REQUEST,
  payload: fromJS({data, onSuccess}),
});

export const updateSuccess = () => ({
  type: types.UPDATE_SUCCESS,
});

export const updateError = () => ({
  type: types.UPDATE_ERROR,
});

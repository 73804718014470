import * as types from './actionTypes';
import {Map} from 'immutable';

const INIT_APP = Map({
  siderCollapsed: localStorage.getItem('siderCollapsed') === 'true',
});

const reducer = (state = INIT_APP, action) => {
  switch (action.type) {
    case types.TOGGLE_SIDER_COLLAPSED:
      localStorage.setItem('siderCollapsed', action.payload);
      return state.set('siderCollapsed', action.payload);
    default:
      return state;
  }
};

export default reducer;

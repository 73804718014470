import {combineReducers} from 'redux-immutable';
import {Map, List} from 'immutable';
import * as types from './actionTypes';
import {PageSize} from "../../../constants/app";

export const REDUX_KEY = "brand";

const INIT_INFO = Map({
  "create": true,
});

const info = (state = INIT_INFO) => {
  return state
}

const INIT_FILTERS = Map({
  current: 1,
  pageSize: PageSize,
});

// LIST
const INIT_LIST = Map({
  filters: INIT_FILTERS,
  loading: false,
  items: List(),
  total: 0,
});

const list = (state = INIT_LIST, action) => {
  switch (action.type) {
    case types.RESET_FILTERS:
      return state.set('filters', INIT_FILTERS);
    case types.LIST_REQUEST:
      return state.update('filters', v => v.merge(action.payload.get('data'))).set('loading', true);
    case types.LIST_ERROR:
      return state.set('loading', false);
    case types.LIST_SUCCESS:
      return state.set('loading', false)
        .set('total', action.payload.get('total'))
        .set('items', action.payload.get('items'));
    default:
      return state;
  }
};

// DETAIL
const INIT_DETAIL = Map({
  id: 0,
  loading: false,
  visible: false,
  item: Map(),
});

const detail = (state = INIT_DETAIL, action) => {
  switch (action.type) {
    case types.DETAIL_REQUEST:
      return state
        .set('loading', true)
        .set('id', action.payload.getIn(['data', 'id']));
    case types.DETAIL_SUCCESS:
      return state
        .set('loading', false)
        .set('item', action.payload);
    case types.DETAIL_ERROR:
      return state.set('loading', false);
    case types.TOGGLE_DETAIL_VISIBLE:
      const visible = action.payload;
      return visible ? state.set('visible', visible) : INIT_DETAIL;
    default:
      return state;
  }
};

// CREATE
const INIT_CREATE = Map({
  loading: false,
});
const create = (state = INIT_CREATE, action) => {
  switch (action.type) {
    case types.CREATE_REQUEST:
      return state.set('loading', true);
    case types.CREATE_SUCCESS:
    case types.CREATE_ERROR:
      return state.set('loading', false);
    default:
      return state;
  }
};

// UPDATE
const INIT_UPDATE = Map({
  loading: false,
  visible: false,
});

const update = (state = INIT_UPDATE, action) => {
  switch (action.type) {
    case types.TOGGLE_UPDATE_VISIBLE:
      return state.set('visible', action.payload);
    case types.UPDATE_REQUEST:
      return state.set('loading', true);
    case types.UPDATE_SUCCESS:
    case types.UPDATE_ERROR:
      return state.set('loading', false);
    default:
      return state;
  }
};

const INIT_MEDIA = Map({
  visible: false,
  index: 0,
  type: 1,
  url: "",
  onSuccess: () => {
  }
});

const media = (state = INIT_MEDIA, action) => {
  switch (action.type) {
    case types.MODIFY_MEDIA:
      return state.merge(action.payload);
    default:
      return state;
  }
}

const reducer = combineReducers({
  info,
  list,
  detail,
  create,
  update,
  media,
});

export default reducer;

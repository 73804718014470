import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { Flex, PassportFormTitle } from '../../components';
import styles from './index.module.scss';
import { accountCheckRequest, loginRequest } from '../../store/reducers/auth/actions';

const mapStateToProps = (state) => ({
  auth: state.get('auth'),
});

const Login = ({auth, loginRequest, history}) => {

  const onFinish = (values) => {
    const onSuccess = () => history.push('/');
    loginRequest(values, onSuccess);
  };

  return (
    <Flex align="center" justify="center" className={styles.wrap}>
      <Form layout="vertical" hideRequiredMark className={styles.form} onFinish={onFinish}>
        <Form.Item>
          <PassportFormTitle title="欢迎登录" />
        </Form.Item>
        <Form.Item name="name" rules={[{required: true, message: '请输入账号'},]}>
          <Input size="large" placeholder="账号为用户名" />
        </Form.Item>
        <Form.Item name="password" rules={[{required: true, message: '请输入登录密码'}]}>
          <Input.Password size="large" placeholder="请输入登录密码" />
        </Form.Item>
        <Button size="large" type="primary" htmlType="submit" loading={auth.get('loading')} block
                style={{marginBottom: "15px"}}>登录</Button>
      </Form>
    </Flex>
  );
}

export default connect(mapStateToProps, {accountCheckRequest, loginRequest})(Login);

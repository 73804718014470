import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import styles from './index.module.scss';
import { PrivateRoute } from '../../components'
import { Skeleton } from 'antd';

const Dashboard = lazy(() => import('../../pages/PlatformDashboard'));
const UserList = lazy(() => import('../../pages/UserList'));
const AdminList = lazy(() => import('../../pages/AdminList'));
const AdminOptList = lazy(() => import('../../pages/AdminOptList'));
const UserOptList = lazy(() => import('../../pages/UserOptList'));
const BrandList = lazy(() => import('../../pages/BrandList'));
const RoleList = lazy(() => import('../../pages/RoleList'));
const Profile = lazy(() => import('../../pages/Profile'));
const Home = lazy(() => import('../../pages/Home'));
const ProductCheck = lazy(() => import('../../pages/ProductCheck'));
const AntiFakeQueryList = lazy(() => import('../../pages/AntiFakeQueryList'));

const PageMain = () => (
  <div className={styles.pageMain}>
    <Suspense fallback={<Skeleton />}>
      <Switch>
        <PrivateRoute path="/AntiFakeQueryList" component={AntiFakeQueryList} />
        <PrivateRoute path="/userList" component={UserList} />
        <PrivateRoute path="/adminList" component={AdminList} />
        <PrivateRoute path="/adminOptList" component={AdminOptList} />
        <PrivateRoute path="/userOptList" component={UserOptList} />
        <PrivateRoute path="/brandList" component={BrandList} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/roleList" component={RoleList} />
        <PrivateRoute path="/productCheck" component={ProductCheck} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/" exact component={Home} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </Suspense>
  </div>
);

export default PageMain;
